import React, { createContext, useEffect, useState } from "react";
export const OrderContext = createContext();
var getInitialise = () => {
    const activeOrder = localStorage.getItem('activeOrder');
    return activeOrder ? JSON.parse(activeOrder) : {
        code: null,
        orders: [],
        active: false,
        expire: new Date().getTime() + 3600000
    }
}

const OrderContextProvider = (props) => {
    const [activeOrder, setActiveOrder] = useState(getInitialise);
    if (new Date().getTime() > activeOrder.expire) {
        localStorage.clear();
        window.location.reload();
    }
    useEffect(() => {
        localStorage.setItem('activeOrder', JSON.stringify(activeOrder));
    }, [activeOrder]);
    console.log(activeOrder);
    let handleChange = (ele) => {
        let temp = { ...ele };
        setActiveOrder(temp);
    }
    return (
        <OrderContext.Provider value={{ activeOrder: activeOrder, handleChange: handleChange }}>
            {props.children}
        </OrderContext.Provider>
    )
}
export default OrderContextProvider;