import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import ImgDetails from '../ImgDetails/ImgDetails';
import { OrderContext } from '../../contexts/OrderContext';
import './person.css';
import Footer from '../Footer/Footer';
const Person = (props) => {
    const location = useLocation();
    const { activeOrder } = useContext(OrderContext);
    const person = location.state ? location.state.person : "null";
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);
    window.scrollTo(0, 0);
    return (
        <section className="main-section person-section">
            <Header title='Nos producteurs' target="/producteurs" state={{}} />
            <div className="main-container">
                <ImgDetails img={person.img} infos={{ main: person.firstName + ' ' + person.lastName, sub: person.job }} content={person.desc} special={true} />               
            </div>
            <Footer />
        </section>
    )
}
export default Person;