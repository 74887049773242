import React, { useContext, useState } from 'react';
import { OrderContext } from '../../contexts/OrderContext';
import { useParams, Link } from 'react-router-dom';
import { SuccessIcon1, SuccessIcon2 } from '../Svgs/Svgs';
import './Success.css';
import Footer from '../Footer/Footer';
const Success = (props) => {
    const { activeOrder } = useContext(OrderContext);

    window.scrollTo(0, 0);
    return (
        <section className="main-section menu-section success-section">
            <div className="main-container main-container-success">
                <div className="success-icon1"><SuccessIcon1 /></div>

                <div className="success-text1">Votre commande a été
                    enregistrée</div>

                <div className="success-icon2"><SuccessIcon2 /></div>

                <div className="success-text2">Elle arrive!</div>

                <div className="success-button">
                    <Link to={'/'} className="buy-button">
                        <div className="buy-button-text">D´accord</div>
                    </Link></div>
                
            </div>
            <Footer />
        </section>
    )
}
export default Success;