import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import './accueil.css';
import Img from '../../images/main.jpg';
import Logo from '../../images/logo.svg';
import { OrderContext } from "../../contexts/OrderContext";
import Footer from "../Footer/Footer";
const Accueil = (props) => {
    let { activeOrder, handleChange } = useContext(OrderContext);
    //let { code } = useParams();
    const [img, setImg] = useState(Img);
    const [buttons, setButtons] = useState([
        {
            id: 0,
            content: 'Notre Concept',
            target: '/concept'
        },
        {
            id: 1,
            content: 'Nos producteurs',
            target: '/producteurs'
        },
        /*  {
              id: 2,
              content: 'Nos évènements',
              target: '/events'
          },*/
        {
            id: 2,
            content: 'Notre Carte',
            target: '/cart'
        }
    ])
    // useEffect(() => {
    //     if (activeOrder.active === null) {
    //         let temp = {
    //             ...activeOrder
    //         };
    //         temp = {
    //             ...temp,
    //             code: code,
    //             active: props.activate
    //         };
    //         handleChange({ ...temp });
    //     }
    // }, []);
    window.scrollTo(0, 0);
    return (
        <section className="main-section accueil-section" style={{ backgroundImage: `url(${Img})` }}>
            <div className="main-content">
                <div className="main-container">
                    <div className="main-header">
                        <span>Bienvenue dans au La Brasserie de la Confluence</span>
                    </div>
                    <div className="buttons">
                        {
                            buttons.map(btn => {
                                return (
                                    <Link to={btn.target} className="main-btn" key={btn.id} state={{}}>{btn.content}</Link>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    )
}
export default Accueil;