import React, { useContext, useState, useEffect } from 'react';
import { OrderContext } from '../../contexts/OrderContext';
import { useParams, Link, useLocation } from 'react-router-dom';
import OrderBtn from '../OrderBtn/OrderBtn';
import Header from '../Header/Header';
import { EmptyGlass, Plus, Minos } from '../Svgs/Svgs';
import MenuElement from '../MenuElement/MenuElement';
import './Bottle.css';
import { Cart } from '../Svgs/Svgs';
import $ from 'jquery';
const Bottle = (props) => {
    let { activeOrder, handleChange } = useContext(OrderContext);
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);
    let { id, mainElemId, subId, name, elem, price, wine, menuName } = checkUrl !== null && checkUrl;
    let targetOrder;
    if (checkUrl !== null) {
        targetOrder = checkUrl.order;
        price = parseFloat(price.replace(',', '.'));
    }

    let [emptyGlassQuantity, setEmptyGlassQuantity] = useState(1);
    let [bottleQuantity, setBottleQuantity] = useState(1);
    let [bottlePrice, setBottlePrice] = useState(price);

    window.scrollTo(0, 0);
    
    let decreaseGlass = (quan) => {
        if (quan > 1) {
            quan -= 1;
            setEmptyGlassQuantity(quan);
        }
    }
    let increaseGlass = (quan) => {
        quan += 1;
        setEmptyGlassQuantity(quan);
    }
    let decreaseBottle = (quan) => {
        if (quan > 1) {
            quan -= 1;
            bottlePrice -= price;
            setBottlePrice(bottlePrice);
            setBottleQuantity(quan);
        }
    }
    let increaseBottle = (quan) => {
        quan += 1;
        bottlePrice += price;
        setBottlePrice(bottlePrice);
        setBottleQuantity(quan);
    }
    let addElem = (ele, glassQuan, bottleQuant, pri) => {
        if (activeOrder.active) {
            let temp = { ...activeOrder };
            temp = {
                ...temp,
                orders: [
                    ...temp.orders,
                    {
                        id: ele.id,
                        menuName: menuName,
                        categoryName: name,
                        elemName: ele.title,
                        description: ele.description,
                        glassCount: glassQuan,
                        bottleCount: bottleQuant,
                        price: pri,
                        mainPrice: price,
                        removed: false,
                        randId: Math.random()
                    }
                ]
            }
            handleChange(temp);
            setEmptyGlassQuantity(1);
            setBottleQuantity(1);
            setBottlePrice(price);
        }

    }
    return (
        checkUrl !== null && <section className="main-section menu-section">
            <Header title={name.toUpperCase()} target={`/element/${id}/${mainElemId}/${subId}`} state={{
                id: id, mainElemId: mainElemId, subId: subId, name: name, elem: elem, wine: wine, menuName: menuName
            }} />
            <div className="buy-container">
                <div className="main-container-bottle">
                    {
                        elem.title ? (
                            <div className="sub-element-name-glass">
                                {elem.title}
                            </div>
                        ) : ''
                    }
                    {
                        elem.description ? (
                            <div className="sub-element-description-glass">
                                {elem.description}
                            </div>
                        ) : ''
                    }
                </div>
                <div className="quantity-area">
                    <div className="quantity-area-minos" onClick={() => decreaseGlass(emptyGlassQuantity)}> <Minos /> </div>
                    <div className="quantity-area-quantity"> {emptyGlassQuantity} </div>
                    <div className="quantity-area-plus" onClick={() => increaseGlass(emptyGlassQuantity)}> <Plus /> </div>
                    <div className="quantity-area-svg">
                        <EmptyGlass />
                    </div>
                </div>

                <div className="buy-button">
                    <div className="buy-button-minos" onClick={() => decreaseBottle(bottleQuantity)}><Minos /></div>
                    <div className="line-between"></div>
                    <Link to={`/menu/${id}`} state={{
                        id: id, mainElemId: mainElemId, subId: subId, name: name, elem: elem, wine: wine, menuName: menuName
                    }} className="buy-button-text" onClick={() => addElem(elem, emptyGlassQuantity, bottleQuantity, bottlePrice)}>{`Ajouter  ${bottleQuantity}`}</Link>
                    <div className="buy-button-price">(€{bottlePrice})</div>
                    <div className="line-between"></div>
                    <div className="buy-button-plus" onClick={() => increaseBottle(bottleQuantity)}><Plus /></div>
                </div>
            </div>
        </section >
    )
}
export default Bottle;