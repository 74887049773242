import React from "react";
import './imgdetails.css';
import ImgInfos from "../ImgInfos/ImgInfos";
import $ from 'jquery';
const ImgDetails = (props) => {
    $(function () {
        $('.img-details .img-side,.img-details .content-side-container').addClass('appear');
    });
    return (
        <div className="img-details">
            <div className="img-side">
                <ImgInfos img={props.img} infos={props.infos} />
            </div>
            <div className="content-side-container">
                <div className="border" style={{ borderColor: props.special ? 'var(--green-color)' : 'var(--blue-color)' }}>

                </div>
                <div className="content-side">
                    {props.content}
                </div>
            </div>
        </div >
    )
}

export default ImgDetails;
