import React, { useState, useEffect, useContext } from "react";
import './orderbtn.css';
import { Cart } from "../Svgs/Svgs";
import { Link } from "react-router-dom";
import { OrderContext } from '../../contexts/OrderContext';
const OrderBtn = (props) => {
    const { activeOrder } = useContext(OrderContext);
    let [count, setCount] = useState(0);
    useEffect(() => {
        activeOrder.orders.map(order => {
            if (!order.removed) {
                count += 1;
            }
        });
        setCount(count);
    }, [])
    return (
        <div className="cart-container">
            <Link to="/yourOrder" className="cart" state={{}}>
                <div className="cart-second-layer">
                    <Cart />
                </div>
                <span className="number">{count}</span>
            </Link>
        </div>
    )
}
export default OrderBtn;