import React from 'react';
import { Link } from 'react-router-dom';
import './producteur.css';
const Producteur = (props) => {
    const { id, firstName, img } = props.producteur;
    return (
        <Link to={'/producteur/'+firstName.replace(/\s+/g, '-').toLowerCase()} className="producteur" state={{ person: props.producteur }}>
            <img src={img} alt="" className="person-img" />
            <div className="name">
                {firstName}
            </div>
        </Link>
    )
}
export default Producteur;