import React, { useContext } from 'react';
import { OrderContext } from '../../contexts/OrderContext';
import { Bottle, Glass } from '../Svgs/Svgs';
import './menuElement.css';
import { Cart } from '../Svgs/Svgs';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const MenuElement = (props) => {
    const { activeOrder } = useContext(OrderContext);
    let subElems;
    if (props.id === '0') {
        subElems = props.elem.vins;
    }
    else if (props.id === '1') {
        subElems = props.elem.boissons;
    }
    else if (props.id === '2') {
        subElems = props.elem.brasse_rierevisitee;
    }
    return (
        <div className="menu-element">
            {
                props.elem.name ? (
                    <div className="menu-element-header menu-element-row" style={{ border: 'none' }}>
                        <div className="info-side">
                            {props.elem.name}
                        </div>
                        {props.id === "0" && <div className="numbers-side" style={{ margin: '0px' }}>
                            <div className='one' style={{ margin: "0px", 'marginRight': "12px" }}>
                                <Glass />
                            </div>
                            <div className='more'>
                                <Bottle />
                            </div>
                        </div>}
                    </div>
                ) : ''
            }

            {
                subElems.map(sub => {
                    let glass, bottle;
                    if (props.isWine) {
                        glass = sub.products.filter(elem => {
                            return parseInt(elem.pivot.type) === 1;
                        });
                        bottle = sub.products.filter(elem => {
                            return parseInt(elem.pivot.type) === 0;
                        });
                    }

                    return (
                        props.isWine ? (
                            activeOrder.active ? (<Link to={`/element/${props.id}/${props.mainElemId}/${sub.id}`} className="menu-element-row" key={sub.id} state={{ id: props.id, mainElemId: props.mainElemId, subId: sub.id, name: props.elem.name, elem: subElems[subElems.indexOf(sub)], wine: props.isWine, menuName: props.menuName }}>
                                <div className="info-side">
                                    <p className="name">
                                        {sub.title}
                                    </p>
                                    {
                                        sub.description ? (
                                            <p className="description">
                                                {sub.description}
                                            </p>
                                        ) : ''
                                    }
                                </div>
                                <div className="numbers-side">
                                    {

                                        // Glass
                                        (glass.length > 0) ? (
                                            <div className='one'>

                                                {
                                                    (glass[0].vatin1 !== '0') ? (
                                                        glass[0].vatin1
                                                    ) : ''
                                                }

                                            </div>
                                        ) : ''
                                    }
                                    {
                                        (bottle.length > 0) ? (<div className='more'>

                                            {
                                                // Bottle[0]
                                                (bottle[0].vatin1 !== '0') ? (
                                                    bottle[0].vatin1
                                                ) : ''
                                            }
                                        </div>) : ''
                                    }
                                </div>
                            </Link>) : (<div className="menu-element-row" key={sub.id} state={{ id: props.id, mainElemId: props.mainElemId, subId: sub.id, name: props.elem.name, elem: subElems[subElems.indexOf(sub)], wine: props.isWine, menuName: props.menuName }} style={{ border: '0px', marginBottom: '5px', alignItems: 'flex-start' }}>
                                <div className="info-side">
                                    <p className="name">
                                        {sub.title}
                                    </p>
                                    {
                                        sub.description ? (
                                            <p className="description">
                                                {sub.description}
                                            </p>
                                        ) : ''
                                    }
                                </div>
                                <div className="numbers-side">
                                    {
                                        // Glass[0]
                                        (glass.length > 0) ? (
                                            <div className='one'>

                                                {
                                                    (glass[0].vatin1 !== '0') ? (
                                                        glass[0].vatin1
                                                    ) : ''
                                                }

                                            </div>
                                        ) : ''
                                    }
                                    {(bottle.length > 0) ?
                                        (<div className='more'>

                                            {
                                                // Bottle[0]
                                                (bottle[0].vatin1 !== '0') ? (
                                                    bottle[0].vatin1
                                                ) : ''
                                            }
                                        </div>) : ''}
                                </div>
                            </div>)
                        ) : (

                            activeOrder.active ?

                                (<Link to={`/glass/${props.id}/${props.mainElemId}/${sub.id}`} className="menu-element-row" key={sub.id * Math.random} state={{
                                    id: props.id, mainElemId: props.mainElemId, subId: sub.id, name: props.elem.name, elem: sub, price: sub.product.vatin1
                                    , wine: props.isWine, menuName: props.menuName
                                }}>
                                    <div className="info-side">
                                        <p className="name">
                                            {sub.title}
                                        </p>

                                        {
                                            sub.description ? (
                                                <p className="description">
                                                    {sub.description}
                                                </p>
                                            ) : ''
                                        }
                                    </div>
                                    <div className="numbers-side">
                                        <div>
                                            {
                                                (sub.product.vatin1 !== '0') ? (
                                                    sub.product.vatin1
                                                ) : ''
                                            }
                                        </div>
                                    </div>
                                </Link>) : (<div className="menu-element-row" key={sub.id * Math.random} state={{
                                    id: props.id, mainElemId: props.mainElemId, subId: sub.id, name: props.elem.name, elem: sub, price: sub.product.vatin1
                                    , wine: props.isWine, menuName: props.menuName
                                }} style={{ border: '0px', marginBottom: '5px', alignItems: 'flex-start' }}>
                                    <div className="info-side">
                                        <p className="name">
                                            {sub.title}
                                        </p>

                                        {
                                            sub.description ? (
                                                <p className="description">
                                                    {sub.description}
                                                </p>
                                            ) : ''
                                        }
                                    </div>
                                    <div className="numbers-side">
                                        <div>
                                            {
                                                (sub.product.vatin1 !== '0') ? (
                                                    sub.product.vatin1
                                                ) : ''
                                            }
                                        </div>
                                    </div>
                                </div>)
                        )
                    )
                }
                )

            }

        </div>
    )
}
export default MenuElement;