import React from "react";
import './footer.css';

const Footer = (props) => {
    return (
        <div className="footer">
            <div className="text">
                Powered by <a href="https://i-web.be/" target="_blank">I-Web</a>
            </div>
        </div>
    )
}
export default Footer;