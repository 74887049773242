import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import './concept.css';
import Img from '../../images/concept.jpg';
import Header from '../Header/Header';
import ImgDetails from '../ImgDetails/ImgDetails';
import { OrderContext } from '../../contexts/OrderContext';
import Footer from '../Footer/Footer';
const Concept = (props) => {
    const [img, setImg] = useState(Img);
    const { activeOrder } = useContext(OrderContext);
    const [content, setContent] = useState(`La Brasserie de la Confluence est une version 2.0 de labrasserie traditionnelle.

    Chez nous, la carte est composée exclusivement de produitslocaux belges.
    La proposition culinaire se veut locale, en lien direct avec lesproducteurs tout en respectant la saisonnalité des produits.
    
    Cette collaboration, basée sur la confiance et l’améliorationcontinue, nous permet aujourd’hui de proposer à nos clientsune cuisine dite de « la graine à l’assiette ».
    
    Nous garantissons une transparence totale sur la provenance,la qualité, la durabilité de tous les ingrédients de tant aupoint de vue environnemental, social qu'économique.
    
    La convivialité est au centre de l'expérience gastronomique,n'hésitez pas
    à nous demander pour partager les différentsplats proposés.`);
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);
    window.scrollTo(0, 0);
    return (
        checkUrl !== null && <section className="main-section concept-section">
            <div className="main-container">
                <Header title='Notre Concept' target={'/'} />
                <ImgDetails img={img} content={content} />
            </div>
            <Footer />
        </section>
    )
}

export default Concept;