import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Header from "../Header/Header";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import './carte.css';
// import required modules
import { Navigation } from "swiper";
import ImgInfos from "../ImgInfos/ImgInfos";
import { OrderContext } from '../../contexts/OrderContext';
import OrderBtn from "../OrderBtn/OrderBtn";
import Footer from "../Footer/Footer";
const Carte = (props) => {
    const [mainElems, setMainElems] = useState([{
        id: 0,
        item: "Nos Vins"
    },
    {
        id: 1,
        item: "Boissons"
    },
    {
        id: 2,
        item: "Brasserie Revisitée"
    },
    {
        id: 3,
        item: "Menu Bistronomique"
    }]);
    // useEffect(() => {
    //     axios.get('data.json').then(res => {
    //         setMainElems(res.data.menu);
    //     })
    // }, []);
    let { activeOrder } = useContext(OrderContext);
    const checkUrl = useLocation().state;
    useEffect(() => {
        if (checkUrl === null) {
            window.location.href = '/directaccess';
        }
    }, []);

    window.scrollTo(0, 0);
    return (
        checkUrl !== null && <section className="main-section carte-section">
            <Header title="Notre Carte" target={'/'} />
            <div className="main-container-flex">

                {/* <div style={{ height: '29.4%' }}> */}

                {
                    mainElems.map(elem => {
                        return (
                            <div className="menu-element-with-friend" key={elem.id}>
                                <Link className="menu-element-row" to={`/menu/${elem.id}`} state={{}}>
                                    {/* <ImgInfos img={elem.img} infos={{ main: elem.root }} /> */}
                                    {elem.item}
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
            {activeOrder.active && <OrderBtn />}
            <Footer />
        </section>
    )
}
export default Carte;