import React from "react";
import ImgInfos from "../ImgInfos/ImgInfos";
import './event.css';
const Event = (props) => {
    return (
        <div className="event">
            <div className="all-details">
                <div className="img-side">
                    <ImgInfos img={props.event.img} infos={{ main: props.event.infos.main, sub: props.event.infos.sub }} />
                </div>
                <div className="text-side">
                    {props.event.mainDesc}
                </div>
            </div>
            <div className="sub-details">
                <img src={props.event.img} alt="" />
                <div className="text">
                    <p>
                        {props.event.subDesc}
                    </p>
                </div>
            </div>
            <div className="blur">

            </div>
        </div>
    )
}
export default Event;